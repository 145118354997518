
const FooterComp = () => {

    return(
        <div className="footer">
            <p>FastLocksmith</p>
        </div>
    );
}

export default FooterComp;