import Header from "./components/header/header.component";
import HomePage from "./components/pages/homepage/homepage.component";


function App() {

  return (
    <div>
      <Header/>
      <HomePage/>
    </div>
  );
}

export default App;
